const idleBreakingEvents = ["mousemove", "keydown", "mousedown", "touchstart"];

window.idleTimerInterval = null;
window.idleTimer = 0;
window.userIdle = false;

function setUpIdleEventListeners() {
    idleBreakingEvents.forEach(function (idleBreakingEvent) {
        document.addEventListener(idleBreakingEvent, function (event) {
            resetIdleTimer();

            if (window.idleTimerInterval === null) {
                console.log("Active User Detected");
                startIdleTimerInterval();
            }
        });
    });
}

function startIdleTimerInterval() {
    window.idleTimerInterval = setInterval(function () {
        window.idleTimer++;

        if (window.idleTimer >= window.idleTimeoutSeconds) {
            console.log("Idle User Detected");
            window.userIdle = true;
            clearInterval(window.idleTimerInterval);
            window.idleTimerInterval = null;
        }
    }, 1000);
}

function resetIdleTimer() {
    window.idleTimer = 0;
    window.userIdle = false;
}

setUpIdleEventListeners();
startIdleTimerInterval();

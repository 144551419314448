import lodash from "lodash";
import Axios from "axios";
import jQuery from "jquery";

window.jQuery = jQuery;
window._ = lodash;
window.axios = Axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";


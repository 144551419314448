export default {
  content: [
    './vendor/laravel/framework/src/Illuminate/Pagination/resources/views/*.blade.php',
    './storage/framework/views/*.php',
    './resources/views/**/*.blade.php',
    './resources/**/**.vue',
  ],
  plugins: [],
  theme: {
    container: (theme) => ({
      center: true,
      padding: theme('spacing.4'),
    }),
    screens: {
      xs: '320px',
      sm: '480px',
      md: '768px',
      lg: '1080px',
      xl: '1440px',
    },
    fontFamily: {
      body: ['Inter', 'serif'],
      display: ['Inter', 'serif'],
      monospace: ['"Roboto Mono"', 'monospace'],
    },
    fontSize: {
      base: '18px',
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal',
      square: 'square',
      latin: 'upper-latin',
      roman: 'upper-roman',
    },
    borderWidth: {
      DEFAULT: '1px',
      0: '0',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px',
      8: '8px',
    },
    borderRadius: {
      none: '0',
      sm: '0.125rem',
      DEFAULT: '0.375rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.75rem',
      '2xl': '1rem',
      '3xl': '1.5rem',
      full: '9999px',
    },
    colors: {
      transparent: 'transparent',
      black: '#171C29',
      white: '#FFF',
      text: '#1B1B1B',
      primary: {
        lightest: '#e5f8ff',
        lighter: '#9BCEE4',
        light: '#3d9ff3',
        default: '#2195F3',
        dark: '#1584de',
        darker: '#08599a',
        darkest: '#05487e',
      },
      secondary: {
        lightest: '#e0ada4',
        lighter: '#e17b69',
        light: '#e16049',
        default: '#E25941',
        dark: '#c44731',
        darker: '#8f3322',
        darkest: '#652316',
      },
      accent: {
        light: '#FFECE9',
        default: '#FED5CE',
        dark: '#6E2D74',
      },
      gray: {
        50: '#F6F7FC',
        100: '#F1F3F8',
        200: '#E7EAF0',
        300: '#DDE1EA',
        400: '#CBCED7',
        500: '#B1B5C3',
        600: '#767C8D',
        700: '#5D6375',
        800: '#3E3F40',
        900: '#171C29',
      },
      success: {
        lightest: '#C9FBCE',
        lighter: '#A7EBC2',
        light: '#98CFD0',
        default: '#089B81',
        dark: '#065546',
        darker: '#10542B',
      },
      warning: {
        lightest: '#FFF3D3',
        lighter: '#CD9701',
        light: '#AA5A2D',
        default: '#AA5A2D',
        dark: '#AA5A2D',
        darker: '#AA5A2D',
        darkest: '#AA5A2D',
      },
      danger: {
        lightest: '#FEDEDE',
        lighter: '#F2C8D7',
        light: '#FAB637',
        default: '#CA3A31',
        dark: '#C9235F',
        darker: '#A82727',
        darkest: '#853423',
      },
      score: {
        great: {
          light: '#85ff99',
          default: '#20B174',
          dark: '#3b7745',
        },
        good: {
          light: '#ffa26e',
          default: '#FD8F56',
          dark: '#c44401',
        },
        bad: {
          light: '#da859a',
          default: '#E25941',
          dark: '#c03b5a',
        },
      },
    },
  },
  extend: {
    boxShadow: {
      top: '0px 0px 4px rgba(0, 0, 0, 0.22)',
    },
    animation: {
      'pulse-h': 'pulse-h .25s ease-in-out 1',
    },
    keyframes: {
      'pulse-h': {
        '0%, 100%': { transform: 'translateX(5%)' },
        '50%': { transform: 'translateX(0)' },
      },
    },
  },
  variants: {
    extend: {
      display: ['group-hover', 'hover'],
      zIndex: ['hover'],
      visibility: ['hover', 'focus'],
    },
  },
}
